import React from 'react';
import { TableActionButtons } from 'ui/Table';
import { TableToolbarButtons } from 'ui/Table';
import ExpandedRow from 'ui/Table/ExpandedRow';

const useTableFilteredChildren = (children) => {
  const elements = {
    actionButtons: [],
    toolbarButtons: null,
    expandedRow: null,
  };
  React.Children.forEach(children, (child) => {
    if (child.type === TableActionButtons) {
      React.Children.forEach(child.props.children, (action) => elements.actionButtons.push(action));
    }
    if (child.type === TableToolbarButtons) {
      elements.toolbarButtons = child;
    }
    if (child.type === ExpandedRow) {
      elements.expandedRow = child;
    }
  });

  return elements;
};

export default useTableFilteredChildren;
