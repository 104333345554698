import confirmSVG from 'assets/svg/button_on-01.svg';
import { COLORS } from 'config.js';
import Icon from 'helpers/components/Icon';
import React, { useEffect, useMemo, useState } from 'react';
import ActionButton from 'ui/ActionButton';
import ExpandedRow from '../ExpandedRow';
import TableCell from '../TableCell';
import { useTableContext } from '../TableProvider';
import styles from './tableRow.module.css';

import arrowIcon from 'assets/svg/arrow-01.svg';
import classNames from 'classnames';

const TableRow = ({ children, row }) => {
  const {
    isSelectable,
    columns,
    setSelectedRows,
    setExpandedRows,
    selectedRows,
    expandedRows,
    selectedValues,
    selectedKey,
    rows
  } = useTableContext();
  const [isSelectedRow, setIsSelectedRow] = useState(false);
  const [isExpandedRow, setIsExpandedRow] = useState(false);

  useEffect(() => {
    if (isSelectable)
      setIsSelectedRow(selectedRows?.some((val) => val[selectedKey] === row[selectedKey]));
    if (hasExpandedRow)
      setIsExpandedRow(expandedRows?.some((val) => val[selectedKey] === row[selectedKey]));
  }, [selectedRows, expandedRows, rows]);

  const updateRows = (isRowSelected, rows, setRows) => {
    if (isRowSelected) {
      return setRows(rows.filter((val) => val[selectedKey] !== row[selectedKey]));
    }

    const newObject = selectedValues.reduce((acc, key) => {
      if (key in row) {
        acc[key] = row[key];
      }
      return acc;
    }, {});

    setRows((prevState) => [...prevState, newObject]);
  };

  const handleSelectRow = () => updateRows(isSelectedRow, selectedRows, setSelectedRows);
  const handleExpandRow = () => updateRows(isExpandedRow, expandedRows, setExpandedRows);

  const hasExpandedRow = useMemo(() => {
    return React.Children.toArray(children).some((child) => {
      return React.isValidElement(child) && child.type === ExpandedRow;
    })
  }, [row]);

  const actionButtons = useMemo(() => {
    return React.Children.map(children, (child) => {
      if (child?.type === ActionButton)
        return React.cloneElement(child, { row });
    })
  }, [row])

  const expandedRow = useMemo(() => {
    return React.Children.map(children, (child) => {
      if (child?.type === ExpandedRow)
        return React.cloneElement(child, { row, colSpan: columns.length + 2 });
    })
  }, [row])

  return (
    <>
      <tr className={styles.row}>
        {(isSelectable || hasExpandedRow) && (
          <td className={classNames(styles.cell, styles.actionCell)}>
            {hasExpandedRow && (
              <button className={styles.expandButton} onClick={handleExpandRow}>
                <Icon
                  className={classNames({ [styles.hiddenRow]: !isExpandedRow })}
                  color={COLORS.color_brand}
                  src={arrowIcon}
                  width={'14px'}
                  height={'14px'} />
              </button>
            )}
            {isSelectable && (
              <div className={styles.selectedCell} onClick={handleSelectRow}>
                {isSelectedRow && (
                  <Icon color={COLORS.color_hover2} src={confirmSVG} width={'17px'} height={'17px'} />
                )}
              </div>
            )}
          </td>
        )}
        {columns?.map((val) => (
          val.showColumn && <TableCell key={val.field} cell={row[val.field]} />
        ))}
        {children && (
          <td className={styles.cell}>
            <div className={styles.actions}>{actionButtons}</div>
          </td>
        )}
      </tr>
      {isExpandedRow && expandedRow}
    </>
  );
};

export default TableRow;
