import { modeContext } from 'components/Contexts/contexts';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef } from 'react';
import { DeviceOperations, devicesStore } from 'store/devices.store';
import ActionButton from 'ui/ActionButton';
import Table, { TableActionButtons, TableToolbarButtons } from 'ui/Table';
import styles from './devicesTabPage.module.css';

import editIcon from 'assets/svg/edit-01.svg';
import deleteIcon from 'assets/svg/delete-01.svg';
import addIcon from 'assets/svg/add-01.svg';
import ExpandedRow from 'ui/Table/ExpandedRow';
import { address_server_short, PUBLIC_ASSETS } from '../../../config';
import { formatDate } from 'helpers/utils';

const Devices = observer(({ mode }) => {
  const modeMain = React.useContext(modeContext);
  const { setWidget } = modeMain.widget;
  const tableRef = useRef(null);

  const {
    devices,
    pagination,
    columns,
    setDevices,
    removeDevice,
    setOperationMode,
    setUpdateState,
    setCreateState
  } = devicesStore;

  const addNewDevice = useCallback(() => {
    setOperationMode(DeviceOperations.Add)
    setCreateState(null);
    navigateToUpsert('add');
  }, []);

  const editDevice = useCallback(async (value) => {
    setOperationMode(DeviceOperations.Edit);
    setUpdateState(value)
    navigateToUpsert('edit');
  }, []);

  const deleteDevice = useCallback((event) => {
    setWidget({
      status: 'Sure',
      text: 'Вы действительно хотите удалить данное оборудование?',
      fun: async () => {
        const result = await removeDevice(event.id);

        if (!result.success) {
          errorHandler(result.error_code);
        }
      },
    });
  }, []);


  const onTableUpdate = (options) => {
    setDevices(options);
  }

  const navigateToUpsert = (modeOpen) => {
    const newTab = {
      operation: modeOpen,
      id: new Date().getTime(),
    };
    if (modeOpen == 'add') {
      newTab.name = 'Создать оборудование';
      newTab.path = 'Создать оборудование';
    }

    if (modeOpen == 'edit') {
      newTab.name = 'Редактировать оборудование';
      newTab.path = 'Редактировать оборудование';
    }

    if (mode.tabs.some((tab) => tab.path === newTab.path)) {
      setWidget({
        status: 'SimpleError',
        text: `У вас имеются не сохраненные данные. Завершите работу с ранее открытыми вкладками`,
        fun: () => { },
      });
      return;
    }
    localStorage.setItem('active_tabs', `${newTab.id}`);
    mode.setTabs([...mode.tabs, newTab]);
    mode.setActiveTabId(newTab.id);
    mode.setVisibleAdd(false);
    mode.setPath(newTab.path);
  };

  const errorHandler = (code) => {
    setWidget({
      status: 'Error',
      text: ` Код № ${code}.`,
      fun: async () => { },
    });
  };

  return (
    <div className={styles.pageContainer}>
      <Table
        initialColumns={columns}
        initialRows={devices}
        totalItems={pagination.itemsCount}
        itemsPerPage={pagination.limit}
        page={pagination.page}
        ref={tableRef}
        onUpdate={onTableUpdate}
        isSelectable
        isExpandable
      >
        <TableToolbarButtons>
          <ActionButton
            icon={addIcon}
            styleConfig={{
              container: styles.toolbarActionButtonContainer,
              iconContainer: styles.toolbarActionButton,
              tooltipText: 'Добавить',
            }}
            color='#fff'
            onClick={addNewDevice}
          />
        </TableToolbarButtons>
        <TableActionButtons>
          <ActionButton
            icon={editIcon}
            styleConfig={{
              iconContainer: styles.actionButtonIconContainer,
              tooltipText: 'Редактировать'
            }}
            onClick={editDevice}
          />
          <ActionButton
            icon={deleteIcon}
            styleConfig={{
              iconContainer: styles.actionButtonIconContainer,
              tooltipText: 'Удалить'
            }}
            onClick={deleteDevice}
          />
        </TableActionButtons>
        <ExpandedRow rowExpandedPrepared={(row) => DeviceDetails(row)} />
      </Table>
    </div>
  );
});

const DeviceDetails = (row) => (
  <div className={styles.deviceDetails}>
    <div className={styles.title}>
      {row.model}
      <span className={styles.inv}>(инв. {row.inventory_number})</span>
    </div>
    <div className={styles.iconsContainer}>
      <div className={styles.itemIcon}>
        <div className={styles.containerImg}>
          <img src={address_server_short + PUBLIC_ASSETS + row.image} className={styles.itemImg} alt='N/A' />
        </div>
      </div>
    </div>
    <div className={styles.blockTitle}>Информация</div>
    <div className={styles.detailsField}>
      <div>ID:</div>
      <div>{row.id}</div>
    </div>
    <div className={styles.detailsField}>
      <div>Название:</div>
      <div>{row.name}</div>
    </div>
    <div className={styles.detailsField}>
      <div>Модель:</div>
      <div>{row.model}</div>
    </div>
    <div className={styles.detailsField}>
      <div>Дата создания:</div>
      <div>{formatDate(new Date(row.created_at))}</div>
    </div>
    <div className={styles.detailsField}>
      <div>Дата последнего обновления:</div>
      <div>{formatDate(new Date(row.updated_at))}</div>
    </div>
    <div className={styles.detailsField}>
      <div>Включено:</div>
      <div>{row.is_active ? 'Да' : 'Нет'}</div>
    </div>
    <div className={styles.detailsField}>
      <div>Описание:</div>
      <div>{row.description}</div>
    </div>
    <div className={styles.blockTitle}>Пользовательские параметры</div>
    <div className={styles.regularText}>{row.device_model?.user_fields?.map((field) => field.name).join(', ')}</div>
    <div className={styles.blockTitle}>Характеристики</div>
    <table className={styles.table}>
      <tbody>
        {row.device_model?.specifications?.map((spec, index) => (
          <tr key={index}>
            <td className={styles.cell}>{spec.name}</td>
            <td className={styles.cell}>{spec.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default Devices;