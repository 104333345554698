import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './addDeviceModelModal.module.css';
import Icon from 'helpers/components/Icon';
import classNames from 'classnames';
import closeIcon from 'assets/svg/close-01.svg';
import { UserFields } from 'components/UserFields';
import { devicesStore } from 'store/devices.store';
import { getAllUserFields } from 'services/list.services';
import { MultiItemList } from 'components/Widgets/MultiInputList';
import { modeContext } from 'components/Contexts/contexts';
import { address_server_short, PUBLIC_ASSETS } from '../../../config';
import attentionIcon from 'assets/svg/attention-01.svg';
import TextField from 'components/Widgets/TextField';
import textFieldStyles from 'components/Widgets/TextField/textField.module.css';

const AddDeviceModelModal = ({
  setModalOpened,
  mode,
}) => {
  const mainContext = React.useContext(modeContext);
  const { setWidget } = mainContext.widget;

  const {
    setSelectedModel,
    upsertDeviceModel,
    selectedDeviceModel,
    icons,
    setIcons
  } = devicesStore;
  const userfieldsRef = useRef(null);

  const [deviceDetailes, setDeviceDetailes] = useState([]);
  const [isFormValidate, setIsFormValidate] = React.useState(false);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [image, setImage] = React.useState('');

  useEffect(() => {
    setIcons();
  }, [])

  useEffect(() => {
    setSelectedModel({
      ...selectedDeviceModel,
      name: name,
      description: description,
      image: image
    });
  }, [name, description, image])

  const closeModalClick = () => {
    setModalOpened(false);
  }

  const submitClick = async () => {
    setIsFormValidate(true);
    const userFields = userfieldsRef.current.getSelectedUserfields();

    if (
      !selectedDeviceModel?.name?.trim() ||
      !selectedDeviceModel?.description?.trim() ||
      !selectedDeviceModel?.image?.trim() ||
      !userFields?.length ||
      deviceDetailes.some((item) => !(item?.name?.trim() && item?.value?.trim()))
    )
      return;
      
    if (selectedDeviceModel?.id != null)
      setWidget({
        status: 'Attention',
        text: `Данные модели оборудования будут изменены`,
        fun: async () => { },
      });

    setSelectedModel({
      ...selectedDeviceModel,
      name: name,
      description: description,
      user_fields: userfieldsRef.current.getSelectedUserfields(),
      specifications: deviceDetailes.length ? deviceDetailes : undefined,
      image: image
    });
    const result = await upsertDeviceModel();

    if (!result.success) {
      errorHandler(result.error_code);
    } else {
      setWidget({
        status: 'Ok',
        text:
          selectedDeviceModel.id != null
            ? `Модель оборудования успешно изменена!`
            : 'Модель оборудования добавлена успешно!',
        fun: async () => {
          closeModalClick();
        },
      });
    }
  }

  const changeItemByIndex = (key, value, index) => {
    let res = JSON.parse(JSON.stringify(deviceDetailes));
    let item = res[index];

    if (item) {
      item[key] = value;
    }

    setDeviceDetailes(res);
  };

  const addItem = () => {
    let res = JSON.parse(JSON.stringify(deviceDetailes));
    res.push({ name: '', value: '' });
    setDeviceDetailes(res);
  };

  const deleteItem = (index) => {
    let res = JSON.parse(JSON.stringify(deviceDetailes));
    res.splice(index, 1);
    setDeviceDetailes(res);
  };

  const errorHandler = (code) => {
    setWidget({
      status: 'Error',
      text: ` Код № ${code}.`,
      fun: async () => { },
    });
  };

  const Item = (value, index) => {
    return (
      <div key={index} className={styles.itemRow}>
        <div className={styles.textField}>
          <div className={styles.inputContainer}>
            <div>Название</div>
            <input
              className={styles.input}
              value={value.name}
              onChange={(e) => changeItemByIndex('name', e.target.value, index)}
            />
          </div>
          {!value.name?.trim() && isFormValidate && (
            <div className={styles.dataErrorBlock}>
              <img src={attentionIcon} alt={'attention'} />
              <div className={styles.dataError}>Поле обязательно для заполнения</div>
            </div>
          )}
        </div>
        <div className={styles.textField}>
          <div className={styles.inputContainer}>
            <div>Значение</div>
            <input
              className={styles.input}
              value={value.value}
              onChange={(e) => changeItemByIndex('value', e.target.value, index)}
            />
            <div className={styles.buttonClear} onClick={() => deleteItem(index)}>
              <div className={'close_icon'}></div>
            </div>
          </div>
          {!value.value?.trim() && isFormValidate && (
            <div className={styles.dataErrorBlock}>
              <img src={attentionIcon} alt={'attention'} />
              <div className={styles.dataError}>Поле обязательно для заполнения</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const modal = (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            Добавить модель оборудования
          </div>
          <div className={styles.closeButton} onClick={closeModalClick}>
            <Icon className={styles.closeIconButton} color={'#1d6030'} width={'15px'} height={'15px'} src={closeIcon} />
          </div>
        </div>
        <div className={styles.modalBody}>
          <TextField
            className={styles.formField}
            label={'Наименование'}
            field={name}
            onChange={setName}
            required
            isFormValidate={isFormValidate}
          ></TextField>
          <TextField
            className={styles.formField}
            label={'Описание'}
            field={description}
            onChange={setDescription}
            required
            isFormValidate={isFormValidate}
          ></TextField>
          <div className={classNames(textFieldStyles.formField, styles.iconsField)}>
            <div className={styles.formFieldContainer}>
              <div className={textFieldStyles.label}>
                {"Иконка"}
              </div>
              <div className={styles.iconsContainer}>
                {icons.map((url, index) => (
                  <div
                    key={index}
                    className={classNames({ [styles.bg_blue]: url == image, }, styles.itemIcon)}
                    onClick={() => { setImage(url) }}>
                    <div className={styles.containerImg}>
                      <img src={address_server_short + PUBLIC_ASSETS + url} className={styles.itemImg} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {!image?.trim() && isFormValidate && (
              <div className={styles.dataErrorBlock}>
                <img src={attentionIcon} alt={'attention'} />
                <div className={textFieldStyles.dataError}>Поле обязательно для заполнения</div>
              </div>
            )}
          </div>
          <p className={styles.categoryTitle}>Пользовательские параметры</p>
          <UserFields
            ref={userfieldsRef}
            mode={mode}
            loadApi={(options) => getAllUserFields(options, 'device')}
          />
          <p className={styles.categoryTitle}>Характеристики</p>
          <div className={styles.addContainer}>
            <MultiItemList items={deviceDetailes} onChange={addItem} Item={Item} confirmButton={'Добавить поле'} />
          </div>
        </div>
        <div className={styles.footer}>
          <button className={classNames('button_default', 'save_style')} onClick={submitClick}>
            Сохранить
          </button>
          <button className={classNames('button_default', 'cancel_style')} onClick={closeModalClick}>
            Отмена
          </button>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default AddDeviceModelModal;
