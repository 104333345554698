import classNames from 'classnames';
import styles from './tooltip.module.css';
import { useTooltip } from 'hooks/useTooltip';
import ReactDOM from 'react-dom';

const Tooltip = ({ children = null, text = '', styleConfig: { tooltip } = { tooltip: '' } }) => {
  const { containerRef, tooltipRef, tooltipPosition, showTooltip, hideTooltip } = useTooltip();

  return (
    <div
      ref={containerRef}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      className={styles.container}
    >
      {children}
      {tooltipPosition.visible &&
        text &&
        ReactDOM.createPortal(
          <div
            ref={tooltipRef}
            className={classNames(styles.tooltip, tooltip)}
            style={{
              top: `${tooltipPosition.top}px`,
              left: `${tooltipPosition.left}px`,
              position: 'fixed',
              zIndex: 99999,
            }}
          >
            {text}
          </div>,
          document.body,
        )}
    </div>
  );
};

export default Tooltip;
