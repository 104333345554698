import classNames from 'classnames';
import Icon from 'helpers/components/Icon';
import Tooltip from 'ui/Tooltip';
import styles from './actionButton.module.css';

const ActionButton = ({
  styleConfig: {
    container = '',
    iconContainer = '',
    textContainer = '',
    tooltip = '',
    tooltipText = '',
  },
  icon = '',
  color = '#1aba66',
  text = '',
  row = null,
  onClick,
}) => {
  return (
    <Tooltip text={tooltipText} styleConfig={{ tooltip: tooltip }}>
      <div className={classNames(container, styles.container)} onClick={() => onClick && onClick(row)}>
        <Icon className={iconContainer} src={icon} color={color} />
        <div className={textContainer}>{text}</div>
      </div>
    </Tooltip>
  );
};

export default ActionButton;
