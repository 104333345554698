import styles from './tableActionBar.module.css'
import searchIcon from '../../../assets/svg/serch-01.svg';
import settingsIcon from '../../../assets/svg/settings-01.svg';
import filterIcon from '../../../assets/svg/icons/icon_filter-01.svg';
import { useTableContext } from '../TableProvider';
import { useEffect, useRef, useState } from 'react';
import Icon from 'helpers/components/Icon';
import Tooltip from 'ui/Tooltip';
import DropdownComponent from 'components/Widgets/Dropdown/DropdownComponent';
import confirmIcon from '../../../assets/svg/button_on-01.svg';

const TableToolbar = ({ children }) => {
  const {
    search,
    setSearch,
    setCurrentPage,
    columns,
    setColumns,
    update
  } = useTableContext();

  const color = '#01b454';
  const columnsSettingsRef = useRef(null);

  const [searchValue, setSearchValue] = useState('');
  const [openSettingsMenu, setOpenSettingsMenu] = useState(false);

  useEffect(() => {
    setCurrentPage(1);
    setSearch(searchValue);
  }, [searchValue]);

  const handleToggleColumnsSetting = (column, value) => {
    const newColumns = JSON.parse(JSON.stringify(columns));
    const editedColumn = newColumns.find((col) => col.field === column.field);

    if (editedColumn) {
      editedColumn.showColumn = value;
      update({ columns: newColumns.filter((col) => col.showColumn).map((col) => col.field) });
    }
  }

  const handleResetColumns = () => {
    update({ columns: []});
  };

  const handleClickOutside = (event) => {
    if (columnsSettingsRef.current && !columnsSettingsRef.current.contains(event.target)) {
      setOpenSettingsMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.actionBar}>
      <div className={styles.customActions}>
        {children}
      </div>
      <div className={styles.defaultActions}>
        <div className={styles.searchInputContainer}>
          <img className={styles.searchIcon} src={searchIcon} alt={'search'} />
          <input
            className={styles.searchInput}
            placeholder={'Отобрать'}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>

        <div className={styles.actions}>
          <Tooltip text={'Фильтр'}>
            <Icon className={styles.icon} src={filterIcon} color={color} />
          </Tooltip>
          <div className={styles.settings} ref={columnsSettingsRef}>
            <div onClick={() => setOpenSettingsMenu(!openSettingsMenu)}>
              <Tooltip text={'Настройки'}>
                <Icon className={styles.icon} src={settingsIcon} color={color} />
              </Tooltip>
            </div>
            <div
              className={styles.columnSettingsContainer}
              style={{ display: openSettingsMenu ? 'block' : 'none' }}
            >
              <button className={styles.resetColumnsButton} onClick={handleResetColumns}>
                Восстановить по умолчанию
              </button>
              {columns.map((item, index) => (
                <label className={styles.columnLabelBlock} key={index}>
                  <div className={styles.checkbox}>
                    <img src={confirmIcon} alt="" className={styles.checkboxArrow} />
                    <input
                      type="checkbox"
                      className={styles.checkboxSquare}
                      onChange={(e) => handleToggleColumnsSetting(item, e.target.checked)}
                      checked={item?.showColumn}
                    />
                  </div>
                  <span>{item.name}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableToolbar;
