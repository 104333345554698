import classNames from 'classnames';
import styles from './tableHeader.module.css';
import { TableSort } from 'consts/table.consts';
import Icon from 'helpers/components/Icon';
import triangle from 'assets/svg/triangle-01.svg';
import { useTableContext } from '../TableProvider';

const Sort = ({ column }) => {
  const {
    setSortedFiled,
    sortedField: { type, field },
  } = useTableContext();

  const handleClick = (columnField, columnType) => {
    if (columnField === field && columnType === type) setSortedFiled({ field: '', type: '' });
    else setSortedFiled({ field: columnField, type: columnType });
  };

  return (
    column?.sort && (
      <div className={styles.sortContainer}>
        <Icon
          src={triangle}
          className={classNames(styles.sortUp, {
            [styles.visibleOff]: field === column.field && type === TableSort.asc,
          })}
          color={field === column.field ? '#01b454' : 'slategrey'}
          onClick={() => handleClick(column.field, TableSort.desc)}
        />
        <Icon
          src={triangle}
          className={classNames(styles.sortDown, {
            [styles.visibleOff]: field === column.field && type === TableSort.desc,
          })}
          color={field === column.field ? '#01b454' : 'slategrey'}
          onClick={() => handleClick(column.field, TableSort.asc)}
        />
      </div>
    )
  );
};

export default Sort;
