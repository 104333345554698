import classNames from 'classnames';
import tableRowStyles from '../TableRow/tableRow.module.css';
import styles from './expandedRow.module.css';

const ExpandedRow = ({ rowExpandedPrepared, row = undefined, colSpan = undefined }) => {
  return (
    <tr id="expanded-row" className={classNames(tableRowStyles.row, styles.expandedContent)}>
      <td colSpan={colSpan ?? 1}>{rowExpandedPrepared(row)}</td>
    </tr>
  );
};

export default ExpandedRow;
