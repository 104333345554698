import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';

const TableContext = createContext(null);

export const useTableContext = () => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error('useTableContext must be used within a TableProvider');
  }
  return context;
};

export const TableProvider = ({
  children,
  initialRows = [],
  initialColumns = [],
  totalItems,
  itemsPerPage,
  page,
  isSelectable = false,
  isExpandable = false,
  selectedValues = ['id'],
  selectedKey = 'id',
  styleConfig = {
    table: '',
    defaultCell: '',
    header: { row: '', cell: '' },
  },
  onUpdate = undefined
}) => {
  const [rows, setRows] = useState(initialRows);
  const [columns, setColumns] = useState(() => initialColumns);
  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isAction, setIsAction] = useState(false);
  const [sortedField, setSortedFiled] = useState({ type: '', field: '' });
  const [currentPage, setCurrentPage] = useState(() => page);
  const [totalItemsLength, setTotalItemsLength] = useState(() => totalItems);
  const [itemsPerPageCount, setItemsPerPageCount] = useState(() => itemsPerPage);
  const [search, setSearch] = useState();

  useEffect(() => {
    setRows(initialRows);
    setColumns(initialColumns);
  }, [initialRows, initialColumns]);

  useEffect(() => {
    setTotalItemsLength(totalItems);
    setItemsPerPageCount(itemsPerPage);
  }, [totalItems, itemsPerPage]);

  useEffect(() => {
    update({ page: currentPage, search: search ? search : undefined, sorting: sortedField})
  }, [currentPage, search, sortedField]);

  const update = useCallback(({
    page = currentPage,
    search = undefined,
    sorting = undefined,
    columns = undefined
  }) => {
    if (onUpdate) {
      onUpdate({
        page,
        search,
        sorting,
        columns
      });
    }
  }, [currentPage, search, sortedField]);

  const value = useMemo(
    () => ({
      setRows,
      setColumns,
      setSelectedRows,
      setExpandedRows,
      setIsAction,
      rows,
      columns,
      selectedRows,
      expandedRows,
      currentPage,
      setCurrentPage,
      totalItemsLength,
      itemsPerPageCount,
      isSelectable,
      setSortedFiled,
      styleConfig,
      sortedField,
      isAction,
      selectedKey,
      selectedValues,
      search,
      setSearch,
      update,
      isExpandable
    }),
    [rows, columns, selectedRows, expandedRows, isSelectable, isExpandable, sortedField, styleConfig, isAction, currentPage],
  );

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};
