import React from 'react';
import classes from './css/multiInputList.module.css';

export const MultiItemList = ({ items, onChange, Item, confirmButton = undefined }) => {
  return (
    <div className={classes.multiItemContainer}>
      {items?.map((item, index) => Item(item, index))}
      <button className={'button_default save_style'} onClick={onChange}>
        {confirmButton ? confirmButton : 'Добавить'}
      </button>
    </div>
  );
};
