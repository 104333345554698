import { useEffect } from 'react';
import cyrillicToTranslit from 'cyrillic-to-translit-js';

function useOutsideClick(ref, setFunc) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setFunc(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

function transliterate(word) {
  return cyrillicToTranslit().transform(word);
}

function unTransliterate(word) {
  return cyrillicToTranslit().reverse(word);
}

export const formatDate = (
  date,
  reverse = false,
  seconds = false,
  milliseconds = false
) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const sec = String(date.getSeconds()).padStart(2, "0");
  const millisec = String(date.getMilliseconds()).padStart(2, "0");

  if (reverse)
    return `${hours}:${minutes}${seconds ? ":" + sec : ""
      } ${day}-${month}-${year}`;

  return `${day}-${month}-${year} ${hours}:${minutes}${seconds ? ":" + sec : ""}${milliseconds ? "." + millisec : ""}`;
};

export default useOutsideClick;
export { useOutsideClick };
export { transliterate };
export { unTransliterate };
