import { useEffect, useRef } from 'react';
import styles from './actionToolbar.module.css';
import arrowIcon from 'assets/svg/arrow-01.svg';
import Icon from 'helpers/components/Icon';
import searchIcon from 'assets/svg/serch-01.svg';
import { COLORS } from '../../../config';
import React from 'react';
import AddFieldModal from '../AddFieldModal/AddFieldModal';
import { useUserFieldsContext } from '..';

const ActionToolbar = ({
  mode,
  types,
  search,
  onSearch,
  category,
  isAddFieldSuccess
}) => {
  const { loadAllUserFields } = useUserFieldsContext();

  const addFieldDropdownRef = useRef(null);
  const [isOpenDDAddFiled, setIsOpenDDAddFiled] = React.useState(false);
  const [modalAddOpened, setModalAddOpened] = React.useState(null);
  const [idType, setIdType] = React.useState();

  const openAddFiled = () => {
    setIsOpenDDAddFiled(!isOpenDDAddFiled);
  };


  const handleClickOutside = (event) => {
    if (addFieldDropdownRef.current && !addFieldDropdownRef.current.contains(event.target)) {
      setIsOpenDDAddFiled(false);
    }
  };

  useEffect(() => {
    // Добавляем обработчик события клика
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Убираем обработчик при размонтировании
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.btnAddContainer} onClick={() => openAddFiled()}>
        <div className={styles.textAddContainer}>Добавить поле</div>
        <div className={styles.iconAddContainer}>
          <Icon
            color={COLORS.color_hover}
            src={arrowIcon}
            width={'12px'}
            height={'12px'}
            className={`${styles.arrowAddIcon} ${isOpenDDAddFiled ? styles.flippedArrowIcon : ''}`}
            onClick={undefined}
          />
        </div>
        <div
          ref={addFieldDropdownRef}
          className={`${styles.selectTextDD} ${isOpenDDAddFiled ? '' : styles.selectTextDDopacity}`}
        >
          {types &&
            types.map((val, index) => (
              <div
                key={index}
                className={styles.selectTextItemDD}
                onClick={() => {
                  setModalAddOpened(true);
                  setIdType(val.id);
                }}
              >
                {val.name}
              </div>
            ))}
        </div>
      </div>
      <div className={styles.searchContainer}>
        <div className={styles.WorkLeftHeader_search}>
          <img
            className={styles.WorkLeftHeader_search_svg}
            src={searchIcon}
            alt={'search'}
          />
          <input
            className={styles.WorkLeftHeader_search_input}
            placeholder={'Отобрать'}
            value={search}
            onChange={(val) => onSearch && onSearch(val.target.value)}
          />
        </div>
      </div>

      {modalAddOpened && (
        <AddFieldModal
          setModalOpened={setModalAddOpened}
          mode={mode}
          idType={idType}
          category={category}
          onUpdate={() => loadAllUserFields()}
        />
      )}
    </div>
  );
};

export default ActionToolbar;