import { observer } from "mobx-react-lite"
import { forwardRef, useEffect, useState } from "react";
import styles from './textField.module.css';
import attentionIcon from 'assets/svg/attention-01.svg';

const TextField = forwardRef(({ className = '', label, field, required = false, onChange = undefined, isFormValidate }, ref) => {
  const [inputValue, setInputValue] = useState('');
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    event.stopPropagation();
    onChange && onChange(inputValue);
  };

  useEffect(() => {
    setInputValue(field);
  }, [field])

  return (
    <div className={styles.textField}>
      <div className={styles.formFieldContainer}>
        <div className={styles.label}>
          {label}
        </div>

        <div className={styles.inputContainer}>
          <input
            className={styles.input}
            value={inputValue}
            onChange={handleChange}
            type="text"
            placeholder="Введите данные"
          />
        </div>
      </div>
      {required && !field?.trim() && isFormValidate && (
        <div className={styles.dataErrorBlock}>
          <img src={attentionIcon} alt={'attention'} />
          <div className={styles.dataError}>Поле обязательно для заполнения</div>
        </div>
      )}
    </div>
  )
});

export default TextField;