import classNames from 'classnames';
import styles from './table.module.css';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import useTableFilteredChildren from 'hooks/useTableFilteredChildren';
import { TableProvider, useTableContext } from './TableProvider';
import Pagination from './Pagination';
import TableToolbar from './TableToolbar';

const Table = forwardRef(({ children = null, ...rest }, ref) => {
  return (
    <TableProvider {...rest}>
      <TableComponent ref={ref}>{children}</TableComponent>
    </TableProvider>
  );
});

const TableComponent = forwardRef(({ children = null }, ref) => {
  const {
    actionButtons,
    toolbarButtons,
    expandedRow
  } = useTableFilteredChildren(children);
  const {
    selectedRows,
    styleConfig: { table },
    setIsAction,
  } = useTableContext();

  useEffect(() => {
    if (actionButtons) setIsAction(true);
    else setIsAction(false);
  }, [actionButtons]);

  useImperativeHandle(ref, () => ({
    getSelectedRows: () => selectedRows,
  }));

  return (
    <div className={styles.container}>
      <TableToolbar>{toolbarButtons}</TableToolbar>
      <div className={styles.tableContainer}>
        <table className={classNames(styles.table, table)}>
          <TableHeader />
          <TableBody>
            {actionButtons}
            {expandedRow}
          </TableBody>
        </table>
      </div>
      <Pagination />
    </div>
  );
});

TableComponent.displayName = 'TableComponent';
Table.displayName = 'Table';

export default Table;
