import { useEffect } from 'react';
import styles from './userFields.module.css';
import classNames from 'classnames';
import arrowIcon from 'assets/svg/arrow-01.svg';

const Pagination = ({
  totalItemsLength,
  itemsPerPageCount,
  currentPage,
  setCurrentPage
}) => {
  const totalPages = Math.ceil(totalItemsLength / itemsPerPageCount);
  const handleClick = (page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  return (
    <div className={styles.pagination}>
      <div className={styles.paging}>
        <div className={styles.paginationPages}>
          <button
            className={styles.leftArrowButton}
            onClick={() => handleClick(currentPage - 1)}
            disabled={currentPage === 1 || totalPages === 0}
          >
            <img className={styles.leftArrow} src={arrowIcon} alt={'arrow'} />
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handleClick(index + 1)}
              disabled={index + 1 === currentPage || totalPages === 0}
              className={classNames(styles.paginationPage, { [styles.currentPage]: index + 1 === currentPage })}
            >
              {index + 1}
            </button>
          ))}

          <button
            className={styles.rightArrowButton}
            onClick={() => handleClick(currentPage + 1)}
            disabled={currentPage === totalPages || totalPages === 0}
          >
            <img className={styles.rightArrow} src={arrowIcon} alt={'arrow'} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;