import classNames from 'classnames';
import styles from './userFields.module.css';
import { TableSort } from 'consts/table.consts';
import Icon from 'helpers/components/Icon';
import triangle from 'assets/svg/triangle-01.svg';
import { useUserFieldsContext } from '.';

const Sort = ({ column }) => {
  const {
    setSortedField,
    sortedField: { type, field },
  } = useUserFieldsContext();

  const handleClick = (columnField, columnType) => {
    if (columnField === field && columnType === type) setSortedField({ field: '', type: '' });
    else setSortedField({ field: columnField, type: columnType });
  };

  return (
    <div className={styles.sortContainer}>
      <Icon
        src={triangle}
        className={classNames(styles.sortUp, {
          [styles.visibleOff]: field === column.field && type === TableSort.asc,
        })}
        color={field === column.field ? '#01b454' : 'slategrey'}
        onClick={() => handleClick(column.field, TableSort.desc)}
      />
      <Icon
        src={triangle}
        className={classNames(styles.sortDown, {
          [styles.visibleOff]: field === column.field && type === TableSort.desc,
        })}
        color={field === column.field ? '#01b454' : 'slategrey'}
        onClick={() => handleClick(column.field, TableSort.asc)}
      />
    </div>
  );
};

export default Sort;
