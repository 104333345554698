import { useState, useRef, useEffect } from 'react';

export const useTooltip = () => {
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: 0,
    left: 0,
    visible: false,
  });

  const calculatePosition = () => {
    if (!containerRef.current || !tooltipRef.current) {
      console.error('Element not found');
      return;
    }

    const containerRect = containerRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Смещения для диагонального отображения
    const offsetX = 3; // смещение по горизонтали
    const offsetY = 25; // смещение по вертикали

    // Позиции тултипа относительно контейнера
    const positions = {
      bottom: {
        top: containerRect.bottom + offsetY, // смещение вниз
        left: containerRect.left + containerRect.width / 2 - tooltipRect.width / 2 + offsetX, // смещение вправо
      },
      top: {
        top: containerRect.top - tooltipRect.height - offsetY, // смещение вверх
        left: containerRect.left + containerRect.width / 2 - tooltipRect.width / 2 - offsetX, // смещение влево
      },
      left: {
        top: containerRect.top + containerRect.height / 2 - tooltipRect.height / 2 + offsetY,
        left: containerRect.left - tooltipRect.width - offsetX,
      },
      right: {
        top: containerRect.top + containerRect.height / 2 - tooltipRect.height / 2 - offsetY,
        left: containerRect.right + offsetX,
      },
    };

    const fitsInViewport = (pos) => {
      return (
        pos.top >= 0 &&
        pos.left >= 0 &&
        pos.top + tooltipRect.height <= viewportHeight &&
        pos.left + tooltipRect.width <= viewportWidth
      );
    };

    // Приоритет: сначала проверяем снизу, затем слева, справа, сверху
    if (fitsInViewport(positions.bottom)) {
      setTooltipPosition({ ...positions.bottom, visible: true });
    } else if (fitsInViewport(positions.left)) {
      setTooltipPosition({ ...positions.left, visible: true });
    } else if (fitsInViewport(positions.right)) {
      setTooltipPosition({ ...positions.right, visible: true });
    } else if (fitsInViewport(positions.top)) {
      setTooltipPosition({ ...positions.top, visible: true });
    } else {
      // Если нет места, показываем тултип снизу по умолчанию
      setTooltipPosition({ ...positions.bottom, visible: true });
    }
  };

  const showTooltip = () => {
    setTooltipPosition((prev) => ({ ...prev, visible: true }));
  };

  const hideTooltip = () => {
    setTooltipPosition((prev) => ({ ...prev, visible: false }));
  };

  useEffect(() => {
    if (tooltipPosition.visible && tooltipRef.current) {
      calculatePosition();
    }
  }, [tooltipPosition.visible]);

  return {
    containerRef,
    tooltipRef,
    tooltipPosition,
    showTooltip,
    hideTooltip,
  };
};
