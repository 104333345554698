import { useMemo } from 'react';
import TableRow from '../TableRow';
import { useTableContext } from '../TableProvider';
import styles from './tableBody.module.css';
import tableCellsStyles from '../TableCell/tableCell.module.css';
import tableRowStyles from '../TableRow/tableRow.module.css';
import classNames from 'classnames';

const TableBody = ({ children }) => {
  const { rows, columns } = useTableContext();

  const rowsMemo = useMemo(() => {
    if (!rows || rows.length === 0) {
      return (
        <tr className={classNames(tableRowStyles.row)}>
          <td className={classNames(tableCellsStyles.cell, styles.noDataRow)} colSpan={columns.length + 2}>
            Нет данных
          </td>
        </tr>
      );
    }

    return rows.map((row, index) => (
      <TableRow key={index} row={row}>
        {children}
      </TableRow>
    ));
  }, [rows, children]);

  return <tbody>{rowsMemo}</tbody>;
};

export default TableBody;
