import classNames from 'classnames';
import styles from './tableHeader.module.css';
import Sort from './Sort';
import { useTableContext } from '../TableProvider';

const TableHeader = () => {
  const {
    columns,
    isAction,
    isSelectable,
    isExpandable,
    styleConfig: {
      header: { row, cell },
    },
  } = useTableContext();
  return (
    <thead>
      <tr className={classNames(styles.row, row)}>
        {(isSelectable || isExpandable) && <th key={'select'} className={styles.cell} />}
        {columns?.map((column) => (
          column.showColumn && (<th key={column.field} className={classNames(styles.cell, cell)}>
            <div className={styles.cellContainer}>
              {column?.name}
              <Sort column={column} />
            </div>
          </th>)
        ))}
        {isAction && <th key={'action'} className={classNames(styles.cell, styles.cellAction)} />}
      </tr>
    </thead>
  );
};

export default TableHeader;
