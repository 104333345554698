import styles from './userFields.module.css';
import Sort from './Sort';

const TableHeader = ({ columns, isSortable }) => {
  return (
    <thead className={styles.tableHeader}>
      <tr>
        <th key={'select'} className={styles.cell}>
          <div className={styles.selectedCell} />
        </th>
        <th key={'expand'} className={styles.cell}>
          <div className={styles.cell} />
        </th>
        {columns?.map((column) => (
          <th key={column.field} className={styles.column}>
            <div className={styles.cellContainer}>
              {column?.name}
              {isSortable && column.sort && <Sort column={column} />}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
