import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './userFields.module.css';
import Icon from 'helpers/components/Icon';
import add from 'assets/svg/add-01.svg';
import confirmSVG from 'assets/svg/button_on-01.svg';
import { COLORS } from 'config.js';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';

const TableRow = ({ row, isSelected, onSelect, index, moveItem = undefined }) => {
  const [isMoreInfo, setIsMoreInfo] = useState(false);
  const [{ isDragging }, dragRef] = useDrag({
    type: 'item',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [spec, dropRef] = useDrop({
    accept: 'item',
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      if (moveItem) {
        moveItem(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });

  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));

  return (
    <>
      <tr className={styles.row} key={index} ref={dragDropRef}>
        <td className={styles.cell}>
          <div className={styles.selectedCell} onClick={() => onSelect && onSelect(!isSelected)}>
            {isSelected && (
              <Icon
                className={styles.checkBoxIcon}
                color={COLORS.color_hover2}
                src={confirmSVG}
                width={'17px'}
                height={'17px'}
              />
            )}
          </div>
        </td>
        <td className={styles.cell}>
          <div className={styles.collaps} onClick={() => setIsMoreInfo(!isMoreInfo)}>
            {!isMoreInfo ? (
              <Icon
                className={classNames({ [styles.hiddenRow]: !isSelected })}
                color={COLORS.text_secondary}
                src={add}
                width={'10px'}
                height={'10px'}
              />
            ) : (
              <div className={styles.minusInCollaps}></div>
            )}
            <div className={styles.tooltip + ' ' + styles.tooltipInfo}>Раскрыть</div>
          </div>
        </td>
        {row.name && <td className={styles.cell}>{row.name}</td>}
        {row.type.name && <td className={styles.cell}>{row.type.name}</td>}
        {row.required !== undefined && (
          <td className={styles.cell}>{row.required ? 'Да' : 'Нет'}</td>
        )}
      </tr>
      {isMoreInfo && (
        <tr className={styles.moreInfoRow}>
          <td colSpan={5}>
            <div className={styles.moreInfoRowContent}>
              Используется:
              <span className={styles.moreInfoRowTextNormal}>
                {row.info.used_by.map((val, i) => (
                  <span key={i}>
                    {val}
                    {i !== row.info.used_by.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </span>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
