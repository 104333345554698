import { modeContext } from 'components/Contexts/contexts';
import { FetchState } from 'consts/axios.consts';
import { th } from 'date-fns/locale';
import { autorun, makeAutoObservable, runInAction } from 'mobx';
import React, { createContext } from 'react';
import {
  createDevice,
  createDeviceModel,
  deleteDevice,
  deleteDeviceModel,
  getAllDeviceModels,
  getAllDevices,
  getDeviceModelsIcons,
  updateDevice,
} from 'services/device.services';

const NAME = 'devices-store';
export const DeviceOperations = Object.freeze({
  Add: 'add',
  Edit: 'edit',
});

export class DevicesStore {
  devices = [];
  deviceModels = [];
  selectedDeviceModel = null;

  operationMode = null;
  createDeviceState = null;
  updateDeviceState = null;

  pagination = {
    itemsCount: 0,
    limit: 0,
    page: 1,
    pagesCount: 1,
  };
  columns = [];
  icons = [];
  fetchDevices = FetchState.NotLoading;

  constructor() {
    makeAutoObservable(this);

    const storeJSON = localStorage.getItem(NAME);

    if (storeJSON) Object.assign(this, JSON.parse(storeJSON));

    autorun(() => {
      const obj = JSON.parse(JSON.stringify(this));
      localStorage.setItem(NAME, JSON.stringify(obj));
    });

    window.addEventListener('storage', this._updateStore);
  }

  _updateStore = (e) => {
    if (e.key === NAME) {
      const storeJSON = localStorage.getItem(NAME);

      if (storeJSON) Object.assign(this, JSON.parse(storeJSON));
    }
  };

  _dispose = () => {
    window.removeEventListener('storage', this._updateStore);
  };

  setDevices = async (options) => {
    const response = await getAllDevices(options);

    runInAction(() => {
      this.devices = response.data;
      this.pagination = {
        page: response?.pagination?.page,
        pagesCount: response?.pagination?.pages_count,
        itemsCount: response?.pagination?.items_count,
        limit: response?.pagination?.limit,
      };
      this.columns = response?.table;
    });
  };

  setDevicesModels = async () => {
    const response = await getAllDeviceModels();

    runInAction(() => {
      this.deviceModels = response.data;
    });
  };

  setSelectedModel = (model) => {
    runInAction(() => {
      this.selectedDeviceModel = model;
    });
  };

  setOperationMode = (mode) => {
    if (Object.values(DeviceOperations).includes(mode) || mode === null)
      runInAction(() => (this.operationMode = mode));
  };

  upsertDevice = async () => {
    let res;
    if (this.operationMode === DeviceOperations.Edit) {
      res = await updateDevice({ ...this.selectedDevice() });
    } else res = await createDevice({ ...this.selectedDevice() });
    return res;
  };

  removeDevice = async (id) => {
    const res = await deleteDevice(id);

    if (res.success) this.setDevices(this.pagination);
    return res;
  };

  upsertDeviceModel = async () => {
    let res;
    if (this.selectedDeviceModel?.id != null) {
    } else
      res = await createDeviceModel({
        name: this.selectedDeviceModel.name,
        description: this.selectedDeviceModel.description,
        image: this.selectedDeviceModel.image,
        user_fields: this.selectedDeviceModel.user_fields.map((user_field, index) => ({
          id: user_field.id,
          order: index,
        })),
        specifications: this.selectedDeviceModel.specifications?.map((spec, index) => ({
          name: spec.name,
          value: spec.value,
          order: index,
        })),
      });

    this.selectedDeviceModel = null;
    this.setDevicesModels();
    return res;
  };

  removeDeviceModel = async (id) => {
    const res = await deleteDeviceModel(id);

    if (res.success) this.setDevicesModels();
    return res;
  };

  setUpdateState = (device) => {
    runInAction(() => (this.updateDeviceState = device));
  };

  setCreateState = (device) => {
    runInAction(() => (this.createDeviceState = device));
  };

  selectedDevice = () => {
    return this.operationMode === DeviceOperations.Add
      ? this.createDeviceState
      : this.updateDeviceState;
  };

  updateSelectedDevice = (object) => {
    return this.operationMode === DeviceOperations.Add
      ? (this.createDeviceState = object)
      : (this.updateDeviceState = object);
  };

  setIcons = async () => {
    const response = await getDeviceModelsIcons();

    runInAction(() => {
      this.icons = response.data;
    })
  };
}

export const devicesStore = new DevicesStore();
