import classNames from 'classnames';
import styles from './tableCell.module.css';
import { useTableContext } from '../TableProvider';

const TableCell = ({ cell = '' }) => {
  const {
    styleConfig: { defaultCell },
  } = useTableContext();
  return <td className={classNames(styles.cell, defaultCell)}>{cell}</td>;
};

export default TableCell;
